import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'dashboard',
        component: () =>
            import ('../views/Dashboard/Dashboard'),
        meta: {
            title: 'CURS24 - Экосистема ведения шторного бизнеса',
            roles: ["Admin", "Designer", "Seamstress", "Storekeeper", "ProductionDirector", "Driver", "Installer", "Decorator", "Editor"],
        }
    },
    {
        path: '/moneyTransactions',
        name: 'moneyTransactions',
        component: () =>
            import ('../views/MoneyTransactions/MoneyTransactions'),
        meta: {
            title: 'Операции',
            roles: ["Admin", "Designer"],
        }
    },
    {
        path: '/summary',
        name: 'summary',
        component: () =>
            import ('../views/Summary/Summary'),
        meta: {
            title: 'Показатели',
            roles: ["Admin"],
        }
    },
    {
        path: '/sale',
        name: 'sale',
        component: () =>
            import ('../views/Home'),
        meta: {
            title: 'Продажи',
            roles: ["Admin"],
        }
    },
    {
        path: '/customers',
        name: 'customers',
        component: () =>
            import ('../views/Customers/Customers'),
        meta: {
            title: 'Клиенты',
            roles: ["Admin", "Designer"],
        }
    },
    {
        path: '/issues',
        name: 'issues',
        component: () =>
            import ('../views/Issues/Issues'),
        meta: {
            title: 'Задачи',
            roles: ["Admin", "Designer", "Seamstress", "Storekeeper", "ProductionDirector", "Driver", "Installer", "Decorator", "Editor"],
        }
    },
    {
        path: '/issue/:id?',
        name: 'issue',
        component: () =>
            import ('../views/Issues/Issue'),
        props: true,
        meta: {
            title: 'Задача',
            roles: ["Admin", "Designer", "Seamstress", "Storekeeper", "ProductionDirector", "Driver", "Installer", "Decorator", "Editor"],
        }
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () =>
            import ('../views/Calendar/Calendar'),
        meta: {
            title: 'Календарь',
            roles: ["Admin", "Designer", "Seamstress", "Storekeeper", "ProductionDirector", "Driver", "Installer", "Decorator", "Editor"],
        }
    },
    // {
    //   path: '/cashshift/:id?',
    //   name: 'cashShift',
    //   component: () => import('../views/CashShift/CashShift'),
    //   props: true,
    //   meta: {
    //     title: 'Кассовая смена',
    //     roles: ["Designer"],
    //   }
    // },
    // {
    //   path: '/mycashshifts',
    //   name: 'mycashshifts',
    //   component: () => import('../views/MyCashShifts'),
    //   meta: {
    //     title: 'Мои смены',
    //     roles: ["Designer"],
    //   }
    // },

    {
        path: '/customer/:id?',
        name: 'customer',
        component: () =>
            import ('../views/Customers/Customer'),
        props: true,
        meta: {
            title: 'Карточка клиента',
            roles: ["Admin", "Designer"],
        }
    },
    {
        path: '/orders',
        name: 'orders',
        component: () =>
            import ('../views/Orders/Orders'),
        meta: {
            title: 'Заказы',
            roles: ["Admin", "Designer"],
        }
    },
    {
        path: '/projects',
        name: 'projects',
        component: () =>
            import ('../views/Projects/Projects'),
        meta: {
            title: 'Проекты и расчёты',
            roles: ["Admin", "Designer"],
        }
    },
    {
        path: '/purchaseRequests',
        name: 'purchaseRequests',
        component: () =>
            import ('../views/PurchaseRequests/PurchaseRequests'),
        meta: {
            title: 'Комплектация заказов',
            roles: ["Admin", "Designer", "Storekeeper"],
        }
    },
    {
        path: '/order/:id?',
        name: 'order',
        component: () =>
            import ('../views/Orders/Order'),
        props: true,
        meta: {
            title: 'Заказ',
            roles: ["Admin", "Designer"],
        }
    },
    {
        path: '/project/:id?',
        name: 'project',
        component: () =>
            import ('../views/Projects/Project'),
        props: true,
        meta: {
            title: 'Проект',
            roles: ["Admin", "Designer"],
        }
    },
    {
        path: '/project/projectInstance/calculationVariant/:id?',
        name: 'calculationVariant',
        component: () =>
            import ('../views/Projects/CalculationVariant/CalculationVariant.vue'),
        props: true,
        meta: {
            title: 'Проект',
            roles: ["Admin", "Designer"],
        }
    },
    {
        path: '/orderInstance/:id?',
        name: 'orderInstance',
        component: () =>
            import ('../views/Orders/OrderInstance/OrderInstance'),
        props: true,
        meta: {
            title: 'Изделие заказа',
            roles: ["Admin", "Designer"],
        }
    },
    {
        path: '/employees',
        name: 'employees',
        component: () =>
            import ('../views/Employees/Employees'),
        meta: {
            title: 'Сотрудники',
            roles: ["Admin"],
        }
    },
    {
        path: '/workSchedule',
        name: 'workSchedule',
        component: () =>
            import ('../views/WorkSchedule/WorkSchedule'),
        meta: {
            title: 'График работы',
            roles: ["Admin"],
        }
    },
    {
        path: '/products',
        name: 'products',
        component: () =>
            import ('../views/Products/Products'),
        meta: {
            title: 'Каталог товаров',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/pointOfSales',
        name: 'pointOfSales',
        component: () =>
            import ('../views/PointOfSales/PointOfSales'),
        meta: {
            title: 'Салоны',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/stocks',
        name: 'stocks',
        component: () =>
            import ('../views/Stocks/Stocks'),
        meta: {
            title: 'Склады',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/orderInstanceTypes',
        name: 'orderInstanceTypes',
        component: () =>
            import ('../views/OrderInstanceTypes/OrderInstanceTypes'),
        meta: {
            title: 'Типы изделий',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/suppliers',
        name: 'suppliers',
        component: () =>
            import ('../views/Suppliers/Suppliers'),
        meta: {
            title: 'Поставщики',
            roles: ["Admin", "Storekeeper", "Editor"],
        }
    },
    {
        path: '/incomeTypes',
        name: 'incomeTypes',
        component: () =>
            import ('../views/IncomeTypes/IncomeTypes'),
        meta: {
            title: 'Типы прихода денежных средств',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/expenseCategories',
        name: 'expenseCategories',
        component: () =>
            import ('../views/ExpenseCategories/ExpenseCategories'),
        meta: {
            title: 'Категории и статьи расходов',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/measureUnits',
        name: 'measureUnits',
        component: () =>
            import ('../views/MeasureUnits/MeasureUnits'),
        meta: {
            title: 'Единицы измерения',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/closeProjectReasons',
        name: 'closeProjectReasons',
        component: () =>
            import ('../views/CloseProjectReasons/CloseProjectReasons'),
        meta: {
            title: 'Причины отклонения проектов',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/customerSources',
        name: 'customerSources',
        component: () =>
            import ('../views/CustomerSources/CustomerSources'),
        meta: {
            title: 'Источники клиентов',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/provideServices',
        name: 'provideServices',
        component: () =>
            import ('../views/ProvideServices/ProvideServices'),
        meta: {
            title: 'Виды услуг',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/rooms',
        name: 'rooms',
        component: () =>
            import ('../views/Rooms/Rooms'),
        meta: {
            title: 'Помещения',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/priceTypes',
        name: 'priceTypes',
        component: () =>
            import ('../views/PriceTypes/PriceTypes'),
        meta: {
            title: 'Типы цен',
            roles: ["Admin", "Editor"],
        }
    },
    {
        path: '/profile/:id?',
        name: 'profile',
        component: () =>
            import ('../views/Profile.vue'),
        meta: {
            title: 'Профиль',
            roles: ["Admin"],
        }
    },
    {
        path: '/printFormDesigner/:name?/:id?',
        name: 'printFormDesigner',
        component: () =>
            import ('../views/Settings/PrintFormDesigner'),
        meta: {
            title: 'Дизайнер печатных форм',
            roles: ["Admin"],
        }
    },
    {
        path: '/company',
        name: 'company',
        component: () =>
            import ('../views/Company/Company'),
        meta: {
            title: 'Моя компания',
            roles: ["Admin"],
        }
    },
    {
        path: '/requisites/:id?',
        name: 'requisites',
        component: () =>
            import ('../views/Company/Requisites/RequisitesDetail'),
        props: true,
        meta: {
            title: 'Организация',
            roles: ["Admin"],
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () =>
            import ('../views/Settings/Settings'),
        meta: {
            title: 'Настройки',
            roles: ["Admin"],
        }
    },
    {
        path: '/productionRequests',
        name: 'productionRequestsAdmin',
        component: () =>
            import ('../views/Production/ProductionRequests'),
        meta: {
            title: 'Заявки на производство',
            roles: ["Admin"],
        }
    },
    {
        path: '/productionRequests',
        name: 'productionRequests',
        component: () =>
            import ('../views/Production/ProductionRequests'),
        meta: {
            title: 'Заявки на производство',
            roles: ["Designer", "ProductionDirector", "Storekeeper", "Seamstress"],
        }
    },
    {
        path: '/productionPlan',
        name: 'productionPlanAdmin',
        component: () =>
            import ('../views/Production/ProductionPlan'),
        meta: {
            title: 'План-график производства',
            roles: ["Admin"],
        }
    },
    {
        path: '/productionPlan',
        name: 'productionPlan',
        component: () =>
            import ('../views/Production/ProductionPlan'),
        meta: {
            title: 'План-график производства',
            roles: ["Designer", "ProductionDirector", "Storekeeper", "Seamstress"],
        }
    },
    {
        path: '/productsStock',
        name: 'productsStock',
        component: () =>
            import ('../views/ProductsStock/ProductsStock'),
        meta: {
            title: 'Товары на складе',
            roles: ["Admin", "Storekeeper"],
            permition:'AllowStockProduct'
        }
    },
    {
        path: '/productsStock',
        name: 'productsStockDesigner',
        component: () =>
            import ('../views/ProductsStock/ProductsStock'),
        meta: {
            title: 'Товары на складе',
            roles: ["Designer"],
            permition:'AllowStockProduct'
        }
    },    
    {
        path: '/productsDocuments',
        name: 'productsDocuments',
        component: () =>
            import ('../views/ProductsStock/ProductsDocuments'),
        meta: {
            title: 'Складской учёт',
            roles: ["Admin","Storekeeper"],
            permition:'AllowStockProduct'
        }
    },
    {
        path: '/productsRegistration/:id?',
        name: 'productsRegistration',
        component: () =>
            import ('../views/ProductsStock/ProductsRegistration'),
        meta: {
            title: 'Накладная поступления товаров',
            roles: ["Admin","Storekeeper"],
        }
    },
    {
        path: '/productsMoving/:id?',
        name: 'productsMoving',
        component: () =>
            import ('../views/ProductsStock/ProductsMoving'),
        meta: {
            title: 'Перемещение товаров',
            roles: ["Admin"],
        }
    },
    {
        path: '/productsWriteOff/:id?',
        name: 'productsWriteOff',
        component: () =>
            import ('../views/ProductsStock/ProductsWriteOff'),
        meta: {
            title: 'Списание товаров',
            roles: ["Admin","Storekeeper"],
        }
    },
    {
        path: '/salary',
        name: 'salary',
        component: () =>
            import ('../views/Salary/Salary'),
        meta: {
            title: 'Заработная плата',
            roles: ["Admin", "Designer", "Seamstress", "Storekeeper", "ProductionDirector", "Driver", "Installer", "Decorator"],
            permition:'AllowAccrualSalary'
        }
    },
    {
        path: '/reports/сustomerSources',
        name: 'сustomerSourcesReport',
        component: () =>
            import ('../views/Reports/CustomerSourcesReport'),
        meta: {
            title: 'Отчёт по клиентам',
            roles: ["Admin"],
        }
    },
    {
        path: '/reports/orders',
        name: 'ordersReport',
        component: () =>
            import ('../views/Reports/OrdersReport'),
        meta: {
            title: 'Отчёт по заказам',
            roles: ["Admin"],
        }
    },
    {
        path: '/reports/suppliers',
        name: 'salesBySuppliersReport',
        component: () =>
            import ('../views/Reports/SalesBySuppliersReport'),
        meta: {
            title: 'Отчёт по поставщикам',
            roles: ["Admin"],
        }
    },
    {
        path: '/reports/bdr',
        name: 'bdrReport',
        component: () =>
            import ('../views/Reports/BdrReport.vue'),
        meta: {
            title: 'Отчёт ДДС',
            roles: ["Admin"],
        }
    },
    {
        path: '/reports/orderInstanceType',
        name: 'orderInstanceTypeReport',
        component: () =>
            import ('../views/Reports/OrderInstanceTypeReport'),
        meta: {
            title: 'Отчёт по типам изделий',
            roles: ["Admin"],
        }
    },
    {
        path: '/reports/saleProductsReport',
        name: 'saleProductsReport',
        component: () =>
            import ('../views/Reports/SaleProductsReport'),
        meta: {
            title: 'Отчёт по продаже товаров',
            roles: ["Admin"],
        }
    },
    {
        path: '/reports/ordersStatistic',
        name: 'ordersStatisticReport',
        component: () =>
            import ('../views/Reports/OrdersStatisticReport'),
        meta: {
            title: 'Отчёт по заказам',
            roles: ["Admin"],
        }
    },
    {
        path: '/reports/designers',
        name: 'designersReport',
        component: () =>
            import ('../views/Reports/DesignersReport'),
        meta: {
            title: 'Отчёт по дизайнерам',
            roles: ["Admin"],
        }
    },
    {
        path: '/leads',
        name: 'leads',
        component: () =>
            import ('../views/Leads/Leads'),
        meta: {
            title: 'Лиды',
            roles: ["Admin", "Designer"],
            permition:'AllowLeadsIntegration'
        }
    },
    {
        path: '/paymentTypes',
        name: 'paymentTypes',
        component: () =>
            import ('../views/PaymentTypes/PaymentTypes'),
        meta: {
            title: 'Формы платежей',
            roles: ["Admin"],
        }
    },
    {
        path: '/admin/companies',
        name: 'adminCompanies',
        component: () =>
            import ('../views/CompaniesAdmin/Companies'),
        meta: {
            title: 'Компании',
            roles: ["SystemAdmin"],
        }
    },
    {
        path: '/admin/companies/:id?',
        name: 'adminCompany',
        component: () =>
            import ('../views/CompaniesAdmin/CompanyDetail'),
        props: true,
        meta: {
            title: 'Данные компании',
            roles: ["SystemAdmin"],
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/Login.vue'),
        meta: {
            title: 'CURS24 - Вход в систему'
        }
    },
    {
        path: '/404',
        name: '404',
        component: () =>
            import ('../views/404.vue'),
        meta: {
            title: 'Страница не найдена',
        }
    }, {
        path: '*',
        redirect: '/404'
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('auth-info');

    // try to access a restricted page + not logged in
    if (authRequired && !loggedIn) {
        return next('/login');
    }

    next();
});

export default router